<template>
  <div class="LiveManage public-studioAdmin-body">
    <StudioAdminTabs :list="tabs" :newIndex="view" @change="tabsChange"></StudioAdminTabs>
    <div class="contentBox">
      <!-- 筛选 -->
      <div class="selectTop">
        <div class="lBox public-form-studioAdmin">
          <el-input v-model="form.search_title" placeholder="请输入标题关键词" class="input"></el-input>
          <el-input v-model="form.search_realname" placeholder="主持人/创建人姓名" class="input width170"></el-input>

          <el-select v-model="form.search_status" placeholder="直播状态" class="sid" style="width: 120px;margin-right: 10px;"
            v-if="view == 0">
            <el-option v-for="item in optiones_live" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>

          <el-select v-model="form.show_type" placeholder="展示状态" class="sid" style="width: 120px;margin-right: 10px;"
            v-if="view == 0">
            <el-option v-for="item in optiones_display" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>

          <el-select v-model="form.search_audit_status" placeholder="审核状态" class="sid"
            style="width: 120px;margin-right: 10px;" v-if="view == 1">
            <el-option v-for="item in optiones" :key="item.id" :label="item.name" :value="item.id">
            </el-option>
          </el-select>

          <el-button type="primary" @click="LiveTab()">查询</el-button>
          <el-button @click="ClearAll()">重置</el-button>
        </div>
        <div class="rBox">
          <el-button class="btn" @click="toCreate(0)" v-if="view == 0">创建直播</el-button>
        </div>
      </div>
      <!-- 表格 -->
      <div class="tableList">
        <el-table :data="tableData" :default-sort="{ prop: 'date', order: 'descending' }" :stripe="true"
          class="public-table-studioAdmin">
          <el-table-column label="序号" align="center" width="70">
            <template slot-scope="scope">
              {{ scope.$index + 1 }}
            </template>
          </el-table-column>
          <el-table-column label="直播信息" head-align="center">
            <template slot-scope="scope">
              <div class="studioAdmin-info">
                <div class="info-cover">
                  <el-image :src="scope.row.cover_url" :preview-src-list="[scope.row.cover_url]">
                  </el-image>
                  <span class="cover-labelTop" v-if="scope.row.top == 1">{{ scope.row.top == 1 ? "置顶" : "" }}</span>
                  <span class="cover-labelState" :class="scope.row.status | liveStatus">{{ scope.row.title_status
                  }}</span>
                </div>
                <div class="info-box">
                  <p class="info-title">{{ scope.row.title }}</p>
                  <div class="info-block">
                    <span class="block-item">主持人：{{ scope.row.anchor_realname }}</span>
                  </div>
                  <div class="info-block">
                    <span class="block-item">开播时间：{{ scope.row.start_date }} {{ scope.row.start_time }}</span>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="创建人" prop="realname" align="center" width="70" v-if="view == 1"></el-table-column>
          <el-table-column label="创建时间" prop="create_time" align="center" width="180" v-if="view == 1"></el-table-column>
          <el-table-column prop="create_time" label="创建时间/创建人" align="center" width="190" v-if="view == 0">
            <template slot-scope="scope">
              <div class="studioAdmin-info">
                <div class="info-box" style="margin-left: 0;">
                  <div class="info-block">
                    <span class="block-item">{{ scope.row.create_time }}</span>
                  </div>
                  <div class="info-block">
                    <span class="block-item">{{ scope.row.realname }}</span>
                  </div>
                </div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="view_count" label="浏览量" align="center" width="100" v-if="view == 0" />
          <el-table-column label="展示状态" align="center" width="100" v-if="view == 0">
            <template slot-scope="scope">
              <el-switch v-model="scope.row.is_show" active-color="#3489FF" :active-value="1" :inactive-value="0"
                @change="isShowChange(scope.row)">
              </el-switch>
            </template>
          </el-table-column>
          <el-table-column label="审核状态" align="center" width="100" v-if="view == 1">
            <template slot-scope="scope">
              <p class="text-color-orange"
                v-if="scope.row.audit_status == 1 || scope.row.audit_status == 2 || scope.row.audit_status == 3">待审核</p>
              <p class="text-color-red" v-if="scope.row.audit_status == 5">未通过</p>
              <p class="text-color-grey" v-if="scope.row.audit_status == 4">已通过</p>
              <p class="text-color-grey" v-if="scope.row.audit_status == 6">已过期</p>
            </template>
          </el-table-column>
          <el-table-column label="操作" align="center" width="220">
            <template slot-scope="scope">
              <el-button type="text" class="text-color-blue" @click="toDetail(scope.row.id, scope.row)">查看</el-button>
              <template v-if="view == 0">
                <el-button type="text" class="text-color-blue" @click="toCreate(scope.row.id,scope.row.status)">编辑</el-button>
                <el-button type="text" class="text-color-red" @click="deleteLive(scope.row.id)">删除</el-button>
                <el-button type="text" class="text-color-blue" v-if="scope.row.is_show == 1"
                  @click="handleIstop(scope.row)">{{ scope.row.top == 2 ?
                    "置顶" : "取消置顶" }}</el-button>
              </template>
              <template
                v-if="view == 1 && (scope.row.audit_status == 1 || scope.row.audit_status == 2 || scope.row.audit_status == 3)">
                <el-button type="text" @click="examineButton(scope.row, 4)" class="text-color-blue">通过</el-button>
                <el-button type="text" @click="examineButton(scope.row, 5)" class="text-color-red">不通过</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div v-if="total" class="block">
        <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page.sync="form.page" :page-size="form.limit" layout="prev, pager, next,jumper" :total="total">
        </el-pagination>
      </div>
    </div>
  </div>
</template>
<script>
import * as researchApi from '@/api/researchApi.js';
export default {
  data() {
    return {
      optiones_display: [
        {
          id: 1,
          name: "展示",
        },
        {
          id: 2,
          name: "隐藏",
        },
        {
          id: 3,
          name: "置顶",
        },
      ],
      optiones: [
        {
          id: 1,
          name: "待审核",
        },
        {
          id: 4,
          name: "已通过",
        },
        {
          id: 5,
          name: "未通过",
        },
      ],
      optiones_live: [
        {
          id: 1,
          name: "未开始",
        },
        {
          id: 2,
          name: "进行中",
        },
        {
          id: 3,
          name: "已结束",
        },
      ],
      input: "",
      tableData: [
      ],
      //总数
      total: 0,
      //表单数据
      form: {
        page: 1,
        limit: 10,
        search_title: "",
        search_realname: "",
        show_type: "",
        search_status: "",
        audit_status: "",
        search_audit_status: "",
        order_type: 1,
        teaching_studio_id: localStorage.getItem("studioId"),
      },
      //导航
      tabs: [
        {
          label: "直播课管理",
          count: 0
        },
        {
          label: "直播课审核",
          count: 0
        }
      ],
      //页面索引
      view: 0,
      //审核表单数据
      examineForm: {
        id: 0,
        teaching_studio_id: localStorage.getItem("studioId"),
        audit_status: 0,
        refusal: ''
      },
    };
  },
  created() {
    let rouName = this.$route.name
    this.$emit('rouname', rouName)
    //判断是否审核页
    if (this.$route.query.hasOwnProperty('type') && this.$route.query.type == 2) {
      this.view = 1;
      this.form.order_type = 2;
    }
    //初始化
    this.LiveTab();
    this.examineCount();
  },
  computed: {},
  methods: {
    // 置顶操作
    handleIstop(item) {
      let params = {
        top: item.top == 2 ? 1 : 2,
        id: item.id,
      };
      researchApi.get_activity_TeachingStudiolive_top_div(params).then(res => {
        this.LiveTab();
      })
    },
    // 审核按钮
    handleAudit(item, num) {
      let params = {
        audit_status: num,
        id: item.id,
      };
      researchApi.get_activity_TeachingStudiolive_audit_status_count(params).then(res => {
        this.$message.success("操作成功");
        this.LiveTab();
      })
    },
    //重置
    ClearAll() {
      this.form.page = 1;
      this.form.search_title = "";
      this.form.search_realname = "";
      this.form.show_type = "";
      this.form.search_status = "";
      this.form.audit_status = "";
      this.form.search_audit_status = "";
      this.LiveTab();
    },
    //删除直播
    deleteLive(lid) {
      this.$confirm("确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this.$axios.delete("index/TeachingStudioLive/deleteTeachingStudioLive", {
            params: {
              id: lid,
              user_id: localStorage.getItem("id"),
            },
          }).then(() => {
            this.$message.success("删除成功");
            this.LiveTab();
          });
        })
        .catch(() => {

        });
    },
    //跳转详情
    toDetail(lid, item) {
      let routeData = this.$router.resolve({
        path: "/livedetail",
        query: {
          module: 'Live',
          liveid: item.id,
          is_from: 2,
          is_personid: 2,
        },
      });
      window.open(routeData.href, '_blank');
    },

    //直播管理列表
    async LiveTab() {
      let res = await this.$axios.get("index/TeachingStudioLive/listTeachingStudioLive", { params: this.form });
      this.tableData = res.data.data.data || [];
      this.total = res.data.data.total;
    },

    //创建直播
    toCreate(id,status) {
      if (id == 0) {
        this.$router.push(`/CreateLive?is_personid=2&studioId=${this.form.teaching_studio_id}`);
      } else {
        this.$router.push(`/CreateLive?id=${id}&is_personid=2&studioId=${this.form.teaching_studio_id}&editStatus=${status}`);
      }
    },

    //分页
    handleSizeChange(val) {
      this.form.limit = val;
      this.LiveTab();
    },

    handleCurrentChange(val) {
      this.form.page = val;
      this.LiveTab();
    },
    //切换回调
    tabsChange(val) {
      this.form = {
        page: 1,
        limit: 10,
        search_title: "",
        search_realname: "",
        show_type: "",
        search_status: "",
        audit_status: "",
        search_audit_status: "",
        teaching_studio_id: localStorage.getItem("studioId"),
      }
      this.view = val;
      this.form.order_type = val == 0 ? 1 : 2;
      this.examineCount();
      this.LiveTab();
    },
    //是否显示回调
    isShowChange(e) {
      this.$axios
        .put("TeachingStudiolive/set_is_show", {
          id: e.id
        })
        .then(() => {
          this.LiveTab();
        });
    },
    //审核按钮
    examineButton(e, val) {
      this.examineForm.id = e.id;
      this.examineForm.audit_status = val;
      if (val == 4) {
        this.myConfirm({
          content: `是否确定通过审核？`,
          fn: this.examine
        });
      }
      if (val == 5) {
        this.$prompt('', '审核不通过', {
          customClass: 'examine-prompt',
          closeOnClickModal: false,
          showClose: false,
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputType: 'textarea',
          inputPattern: /^.+$/m,
          inputPlaceholder: '请输入拒绝理由',
          inputErrorMessage: '请输入拒绝理由'
        }).then((e) => {
          if (e.action == "confirm") {
            this.examineForm.refusal = e.value;
            this.examine();
          }
        }).catch(() => {

        });
      }
    },
    //审核
    examine() {
      this.$axios
        .put("TeachingStudiolive/audit_status", this.examineForm)
        .then(() => {
          this.LiveTab();
          this.examineCount();
          this.examineForm.refusal = "";
        });
    },
    //审核统计
    examineCount() {
      this.$axios
        .get("index/TeachingStudioLive/audit_status_live", { params: { id: this.form.teaching_studio_id } })
        .then((res) => {
          this.tabs[1].count = res.data.data.audit_status_live;
        });
    },
  },
  filters: {
    liveStatus(number) {
      return `cover-state-color-${number}`;
    },
  }
};
</script>
<style lang="less" scoped>
.LiveManage {
  .navC {
    width: 100%;
    height: 60px;
    padding-left: 26px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #f0f0f0;
  }

  //内容盒子
  .contentBox {
    padding: 26px 26px;

    .selectTop {
      display: flex;
      justify-content: space-between;

      .lBox {
        .input {
          width: 216px;
          margin-right: 10px;
          margin-left: 0;
        }
      }

      .rBox {
        .btn {
          background: #ff8201;
          border: 1px solid #ff8201;
          border-radius: 4px;
          display: flex;
          align-items: center;
          color: #fff;
        }
      }
    }

    //样式
    .block {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;
    }
  }
}
</style>
